import React, { useEffect } from "react";

const AboutAnimation = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://member.psychologytoday.com/verified-seal.js';
    script.dataset.id = '1070309';
    script.dataset.code = 'aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s=';
    script.dataset.badge = '17';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

//useEffect(() => {
  //  const script = document.createElement('script');
  //  script.type = 'text/javascript';
  //  script.src = 'https://member.psychologytoday.com/verified-seal.js';
  //  script.dataset.id = '1070309';
  //  script.dataset.code = 'aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s=';
  //  script.dataset.badge = '17';
  //  document.body.appendChild(script);
//
  //  return () => {
  //    document.body.removeChild(script);
  //  };
  //}, []);
  
  return (
    <>
      <div className="">
        <div
          className="right"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="50"
        >
          <div className="short">
            <h3>Kirati Patel</h3>
            <h5>
              Registered <span className="theme-color">Psychotherapist</span>
            </h5>
          </div>
          <div className="extra">
            <div className="aboutflex">
              <div className="image">
                <img src={`img/about/10.jpg`} alt="Kirati Patel is a registered psychotherapist who provides collaborative psychotherapy services. She has a Master's in Clinical Psychology and her therapeutic approach is influenced by integrative psychodynamic psychotherapy."></img>
              </div>
              <div className="list">
                <p>
                  Hi, I am Kirati (she/her),
                  a Registered Psychotherapist exploring human experiences.
                  I am dedicated to working with individuals and their different <b>levels of consciousness</b>, creating a space for authentic human connection.
                </p>
                <p>
                  My therapeutic approach is client-centered and trauma-informed,
                  rooted in integrative psychodynamic psychotherapy.
                  My work is guided by my interest in the inner child, biology, and human development.
                  In sessions, we will be reflecting on your habitual ways of thinking and behaving,
                  helping you learn more about how your mind works,
                  and supporting you in <b>embracing your true self</b>—all to <b> improve your functioning,
                    relationship with others and strengthen connection to your self.</b>
                </p>
                <p>
                  I am drawn to this work by my own journey of self-discovery.
                  My therapist self is a medium for reflection,
                  dedicated to assisting you in <b>human embodiment</b>- rediscovering,
                  fostering, and encouraging you to embrace your true self.
                  I believe in the inherent completeness, worthiness,
                  and goodness within each of us.
                  Hence, I focus on co-creating a safe and nurturing space through which you can feel empowered to
                  expand your self-awareness, understand your responses, and <b>rediscover your innate resilience</b>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutAnimation;
