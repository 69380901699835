import React, { useEffect, useState, useRef } from "react";

const PsychologyTodayBadge = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const badgeContainerRef = useRef(null);

  useEffect(() => {
    if (badgeContainerRef.current) {
      const script = document.createElement('script');
      script.src = "https://member.psychologytoday.com/verified-seal.js";
      script.type = 'text/javascript';
      script.async = true;
      script.setAttribute("data-badge", "16");
      script.setAttribute("data-id", "1070309");
      script.setAttribute("data-code", "aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s=");
      script.onload = () => {
        setScriptLoaded(true);
        document.dispatchEvent(new Event('DOMContentLoaded'));
      };

      badgeContainerRef.current.appendChild(script);
    }
  }, []);

  return (
    <>
      Psychology Today
      <div ref={badgeContainerRef}>
        <a href="https://www.psychologytoday.com/profile/1070309">
          {scriptLoaded && (
            <a href="https://www.psychologytoday.com/profile/1070309" className="sx-verified-seal"></a>
          )}
        </a>
      </div>
    </>
  );
};

const Badges = () => {
  return (
    <>
      <div className="badge_container">


        <div className="badge_container">
          <a target="_blank" href="https://www.onlinetherapy.com">
            <img src="https://www.onlinetherapy.com/wp-content/uploads/2021/01/white-background-member.png" width="200" alt="Online Therapy" />
          </a>
        </div>


        <div className="badge_container">
          <PsychologyTodayBadge />
        </div>

        <div className="badge_container">
          <a href="https://www.goodtherapy.org/therapists/profile/kirati-patel-psychotherapist" target="_blank">
            <div style={{
              "display": "block !important",
              "margin": "0 !important",
              "padding": "0 !important"
            }}>
              <a href="https://www.goodtherapy.org/therapists/profile/kirati-patel-psychotherapist" target="_blank">
                <img src="https://www.goodtherapy.org/graph/seals/verified_seal3.png" alt="Kirati Patel, Registered Psychotherapist (Qualifying) verified by GoodTherapy.org" />
              </a>
            </div>
          </a>
        </div>
      </div>
      <div className="badge_container">
        <div className="badge">
          <a href="https://www.healingincolour.com/directory?overlay=2ca5de84-5d17-498d-abb0-1250a7722210" target="_blank" rel="noopener noreferrer">
            <img src="https://images.squarespace-cdn.com/content/v1/5d701cca7df27100010e3658/1567629175613-SO0R7ZASRB1X32GUZ9P4/logo_clearbg.png" alt="Healing in Colour Logo" />
          </a>
        </div>
        <div className="badge">
          <a href="https://registration.crpo.ca/mpower/mpp/member-directory-profile?c=18666&n=1&s=kirati%20patel" target="_blank" rel="noopener noreferrer">
            <img src="https://registration.crpo.ca/mpower/common/img/logo/client_logo.jpg" alt="CRPO Logo" />
          </a>
        </div>
        <div className="badge">
          <a href="https://psychodynamiccanada.org/therapist-directory/#/action/Listing/value/13575/cid/1696/id/401/Kirati-Patel" target="_blank" rel="noopener noreferrer">
            <img src="https://psychodynamiccanada.org/wp-content/uploads/2016/02/logo_capt.png" alt="CAPT Logo" />
          </a>
        </div>
        <div className="badge">
          <a href="https://luminohealth.sunlife.ca/en/health-care-provider-profile/psychotherapist/under-a-tree-psychotherapy/kirati-patel-937989-970416" target="_blank" rel="noopener noreferrer">
            <img src="https://luminohealth.sunlife.ca/DHS_PS_STATIC_DOCS/lumino/images/lumino-health-logo.svg" alt="Lumino Health Logo" />
          </a>
        </div>
        <div className="badge">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src="https://assets.volvo.com/is/image/VolvoInformationTechnologyAB/Intersex-inclusive-pride-flag-1?qlt=82&wid=768&ts=1686309900377&dpr=off&fit=constrain" alt="Intersex Inclusive Pride Flag" />
          </a>
        </div>
        <div className="badge">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src="https://assets.volvo.com/is/image/VolvoInformationTechnologyAB/image16?qlt=82&wid=768&ts=1670916922526&dpr=off&fit=constrain&fmt=png-alpha" alt="Mystery Badge" />
          </a>
        </div>
        {/* Add more badges if required */}
      </div>
    </>
  );
};


const Contact = () => {
  return (
    <div className="beny_tm_contact" id="contact">
      <div className="container">
        <div className="contact_inner">
          <div
            className="left"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <div className="beny_tm_title_holder">
              <span>Contact</span>
              <h2>Get In Touch</h2>
              <p>Got questions or interested in a free consultation for either individual, couple or group therapy- I would love to hear from you!</p>
              
            </div>
            <div className="short_list">
              <iframe frameborder='0' height='28' scrolling='no' src='https://underatreepsychotherapy.janeapp.com/embed/book_online' width='177'></iframe>

              <ul>
                <li>
                </li>
                <li>
                  <img className="svg" src="img/svg/phone.svg" alt="" />
                  <a href="Tel: 6473273060">647-327-3060</a>
                </li>
                {/* End li */}

                <li>
                  <img className="svg" src="img/svg/mail.svg" alt="" />
                  <span>
                    <a href="mailto:kiratipatel@protonmail.com">kiratipatel@protonmail.com</a>
                  </span>
                </li>
                {/* End li */}

                <li>
                  <img className="svg" src="img/svg/map.svg" alt="" />
                  <span>
                    Offering virtual sessions in Ontario and British Columbia.<br />
                    Accepting new clients from May 01, 2025
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Badges />
      </div>
    </div>
  );
};

export default Contact;
